/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/primeng/toast/primeng-toast.ngfactory";
import * as i3 from "primeng/toast";
import * as i4 from "primeng/api";
import * as i5 from "./message.component";
import * as i6 from "ngx-toastr";
import * as i7 from "./message.service";
var styles_MessageComponent = [i0.styles];
var RenderType_MessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
export function View_MessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p-toast", [], null, null, null, i2.View_Toast_0, i2.RenderType_Toast)), i1.ɵdid(1, 1294336, null, 1, i3.Toast, [i4.MessageService, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { templates: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i1.ɵdid(1, 245760, null, 0, i5.MessageComponent, [i6.ToastrService, i7.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageComponentNgFactory = i1.ɵccf("app-message", i5.MessageComponent, View_MessageComponent_Host_0, {}, {}, []);
export { MessageComponentNgFactory as MessageComponentNgFactory };
