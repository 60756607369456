import { HttpHeaders, HttpParams, } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { throwError as observableThrowError, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader/loader.service";
import * as i3 from "./message/message.service";
export class RestService {
    constructor(http, loaderService, messagingService) {
        this.http = http;
        this.loaderService = loaderService;
        this.messagingService = messagingService;
        this.blobOutput = false;
        this.htmlOutput = false;
        this.forceLogout = new EventEmitter();
        this.handleOauthError = (error, disableLoader) => {
            if (!disableLoader) {
                this.showLoader(false);
            }
            if (error.error instanceof ErrorEvent) {
                console.log('Client-side error occured.');
                this.messagingService.notify('error', `Error: ${error.code}`, error.statusText);
            }
            else {
                console.log('Server-side error occured.');
                this.messagingService.notify('error', `Error: ${error.status}`, error.error.error_description);
            }
            return observableThrowError(error.error);
        };
        this.handleOauthLogoutError = (error, disableLoader) => {
            if (!disableLoader) {
                this.showLoader(false);
            }
            if (error.error instanceof ErrorEvent) {
                console.log('Client-side error occured.');
            }
            else {
                console.log('Server-side error occured.');
            }
            return observableThrowError(error.error);
        };
        this.handleError = (error, disableLoader) => {
            if (!disableLoader) {
                this.showLoader(false);
            }
            if (error.status === 401) {
                return this.forceLogout.next(true);
            }
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = error.error.message;
                this.messagingService.notify('error', `Error: 999`, errorMessage);
            }
            else {
                // server-side error
                if (error.status) {
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    this.messagingService.notify('error', `Error: ${error.status}`, `${error.message}`);
                }
            }
            return observableThrowError(errorMessage);
        };
    }
    /**
     * GET method implementation in common rest service
     * @param url - get url
     * @param param - url params
     * @param [disableLoader] - enable/disable loader
     * @param [blobOutput] - enable/disable output stringify
     * @param [html] - add headers for html
     * @returns `Observable stream` of the api response
     */
    get(url, param, disableLoader = false, blobOutput, html) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        this.blobOutput = blobOutput;
        this.htmlOutput = html;
        let responseType;
        if (!disableLoader) {
            this.showLoader(true);
        }
        let queryParams = new HttpParams();
        for (const key in param) {
            if (param.hasOwnProperty(key)) {
                queryParams = queryParams.append(key, param[key]);
            }
        }
        if (this.blobOutput) {
            headers = headers.set('Accept', 'application/octet-stream');
            responseType = 'blob';
        }
        else if (this.htmlOutput) {
            headers = headers.delete('Accept');
            responseType = 'text';
        }
        else {
            headers = headers.delete('Accept');
            responseType = 'json';
        }
        this.options = {
            headers,
            params: queryParams,
            responseType,
            withCredentials: true,
        };
        return this.http
            .get(url, this.options).pipe(map((data) => this.handleResponse(data, disableLoader)), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    /**
     * POST method implementation in common rest service
     * @param url - get url
     * @param param - url params
     * @param [disableLoader] - enable/disable loader
     * @param [upload] - add headers for form-data
     * @param [blobOutput] - enable/disable output stringify
     * @returns `Observable stream` of the api response
     */
    post(url, param, disableLoader = false, upload, blobOutput, html) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        let responseType = 'json';
        this.blobOutput = blobOutput;
        this.htmlOutput = html;
        let body = param;
        if (upload) {
            // Other header params are not accepted in upload (server)
            headers = new HttpHeaders({
                enctype: 'multipart/form-data',
            });
        }
        else {
            body = JSON.stringify(param);
        }
        if (!disableLoader) {
            this.showLoader(true);
        }
        if (this.blobOutput) {
            responseType = 'blob';
        }
        this.options = {
            headers,
            responseType,
            withCredentials: true,
        };
        return this.http.post(url, body, this.options).pipe(map((data) => this.handleResponse(data, disableLoader)), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    /**
     * PUT method implementation in common rest service
     * @param url - get url
     * @param param - url params
     * @param [disableLoader] - enable/disable loader
     * @param [upload] - add headers for form-data
     * @returns `Observable stream` of the api response
     */
    put(url, param, disableLoader = false, upload, html) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        this.htmlOutput = html;
        let body = param;
        if (upload) {
            // Other header params are not accepted in upload (server)
            headers = new HttpHeaders({
                enctype: 'multipart/form-data',
            });
        }
        else {
            // headers = new HttpHeaders({
            //   'Content-Type': 'application/json'
            // });
            body = JSON.stringify(param);
        }
        if (!disableLoader) {
            this.showLoader(true);
        }
        if (html) {
            body = param;
        }
        else {
            body = JSON.stringify(param);
        }
        this.options = {
            headers,
            withCredentials: true,
        };
        return this.http.put(url, body, this.options).pipe(map((data) => this.handleResponse(data, disableLoader)), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    /**
     * DELETE method implementation in common rest service
     * @param url - get url
     * @param param - url params
     * @param [disableLoader] - enable/disable loader
     * @returns  `Observable stream` of the api response
     */
    delete(url, param, disableLoader = false) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        this.htmlOutput = false;
        if (!disableLoader) {
            this.showLoader(true);
        }
        let queryParams = new HttpParams();
        for (const key in param) {
            if (param.hasOwnProperty(key)) {
                queryParams = queryParams.append(key, param[key]);
            }
        }
        this.options = {
            headers,
            params: queryParams,
            withCredentials: true,
        };
        return this.http
            .delete(url, this.options).pipe(map((data) => this.handleResponse(data, disableLoader)), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    /**
     * POST method implementation in common rest service only for EMBRIDGE calls
     * @param url - get url
     * @param param - url params
     * @param [disableLoader] - enable/disable loader
     * @param [upload] - add headers for form-data
     * @param [blobOutput] - enable/disable output stringify
     * @returns `Observable stream` of the api response
     */
    emBridgePost(url, param, disableLoader = false, upload, blobOutput) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        let responseType = 'json';
        this.blobOutput = blobOutput;
        let body = param;
        if (upload) {
            // Other header params are not accepted in upload (server)
            headers = new HttpHeaders({
                enctype: 'multipart/form-data',
            });
        }
        else {
            body = JSON.stringify(param);
        }
        if (!disableLoader) {
            this.showLoader(true);
        }
        if (this.blobOutput) {
            responseType = 'blob';
        }
        this.options = {
            headers,
            responseType,
            withCredentials: false,
        };
        return this.http.post(url, body, this.options).pipe(map((data) => {
            if (!disableLoader) {
                this.showLoader(false);
            }
            if (data.errorCode || data.errorMessage) {
                this.messagingService.notify('error', `Error: ${data.errorCode}`, data.errorMessage);
                return observableThrowError(data.errorCode);
            }
            return data;
        }), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    /**
     *
     * OAUTHLOGOUT method implementation in common rest service
     * @param url - get url
     * @param  param - url params
     * @param  [disableLoader] - boolean
     * @param  [blobOutput] - boolean
     * @param  [html] - enable/disable parse
     * @returns  - subscribe for reponse
     */
    oauthLogout(url, param, disableLoader = false, blobOutput, html) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        this.blobOutput = blobOutput;
        this.htmlOutput = html;
        let responseType;
        if (!disableLoader) {
            this.showLoader(true);
        }
        let queryParams = new HttpParams();
        for (const key in param) {
            if (param.hasOwnProperty(key)) {
                queryParams = queryParams.append(key, param[key]);
            }
        }
        if (this.blobOutput) {
            headers = headers.set('Accept', 'application/octet-stream');
            responseType = 'blob';
        }
        else {
            headers = headers.delete('Accept');
            responseType = 'json';
        }
        this.options = {
            headers,
            params: queryParams,
            responseType,
            withCredentials: true,
        };
        return this.http
            .get(url, this.options).pipe(map((data) => this.handleOauthResponse(data, disableLoader)), catchError((error) => this.handleOauthLogoutError(error, disableLoader)));
    }
    handleOauthResponse(res, disableLoader) {
        if (!disableLoader) {
            this.showLoader(false);
        }
        const body = res;
        return body || {};
    }
    handleResponse(res, disableLoader) {
        if (!disableLoader) {
            this.showLoader(false);
        }
        const body = res;
        let message1;
        if (body.status) {
            message1 = body.status.message;
            if (body.status.code !== 0) {
                this.messagingService.notify('error', `Error: ${body.status.code}`, message1);
            }
            else if (body.status.message != null) {
                this.messagingService.notify('success', 'Success', message1);
            }
        }
        if (this.blobOutput) {
            return res || {};
        }
        if (this.htmlOutput) {
            return res || {};
        }
        return body || {};
    }
    getText(url, param, disableLoader = false) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Pragma', 'no-cache');
        let responseType;
        if (!disableLoader) {
            this.showLoader(true);
        }
        let queryParams = new HttpParams();
        for (const key in param) {
            if (param.hasOwnProperty(key)) {
                queryParams = queryParams.append(key, param[key]);
            }
        }
        responseType = 'text';
        this.options = {
            headers,
            params: queryParams,
            responseType,
            withCredentials: true,
        };
        return this.http
            .get(url, this.options).pipe(map((data) => this.handleOauthResponse(data, disableLoader)), catchError((error) => {
            this.handleError(error, disableLoader);
            return observableThrowError(error);
        }));
    }
    showLoader(enable) {
        if (enable) {
            this.loaderService.show();
        }
        else {
            this.loaderService.hide();
        }
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.MessageService)); }, token: RestService, providedIn: "root" });
