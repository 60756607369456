import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class MessageService {
    constructor() {
        this.notificationChange = new Subject();
    }
    /**
     * Notification Message Service
     * @param severity - accepts severity types `success` `info` `warn` `error`.
     * @param summary - heading for the notification
     * @param detail - details of the notfication
     */
    notify(severity, summary, detail) {
        this.notificationChange.next({ severity, summary, detail, timeOut: 5000 });
    }
}
MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(); }, token: MessageService, providedIn: "root" });
