import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
// import { DragDropDirective } from 'directives/dragdrop.directive';
// import { TextUpperCaseDirective } from 'directives/textcapitalize.directive';

@NgModule({
  // declarations: [TextUpperCaseDirective, DragDropDirective] ,
  exports: [
    // DragDropDirective,
    // TextUpperCaseDirective,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
})
export class SharedModule  { }
