import { HttpClient , HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader , TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageComponent } from '../core/message/message.component';
import { HttpTranslateLoaderFactory } from '../core/i18n.loader';
import { LoaderComponent } from '../core/loader/loader.component';
import { LoaderService } from '../core/loader/loader.service';
import { RestService } from '../core/rest.service';
import { SharedServiceModule } from '../services/sharedservice.module';
import { I18nService } from './../core/i18n.service';
import { CommunicationService } from './../services/communication.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { LoginComponent } from './login';
import { MenuModule } from './menu/menu.module';
import { NoContentComponent } from './no-content';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng/api/primeng-api';
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    MessageComponent,
    LoaderComponent,
    LoginComponent,
    NoContentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ToastModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    InputTextModule,
    SharedModule,
    SharedServiceModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpTranslateLoaderFactory,
          deps: [HttpClient]
      }
  }),
  AppRoutingModule,
    MenuModule, // Common Module for Menu and pipes
    CheckboxModule, AccordionModule.forRoot(),
  ],
  providers: [
    LoaderService,
    I18nService,
    MessageService,
    CookieService,
    {
      deps: [I18nService],  //  service handling global settings
      provide: LOCALE_ID,
      useFactory: (I18nService) => I18nService.language  //  locale string
    },
    RestService,
    CookieService,
    I18nService,
    CommunicationService,
  ],
})
export class AppModule {

  constructor() { }

}
