/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../core/message/message.component.ngfactory";
import * as i3 from "../core/message/message.component";
import * as i4 from "ngx-toastr";
import * as i5 from "../core/message/message.service";
import * as i6 from "../core/loader/loader.component.ngfactory";
import * as i7 from "../core/loader/loader.component";
import * as i8 from "../core/loader/loader.service";
import * as i9 from "@angular/router";
import * as i10 from "./app.component";
import * as i11 from "@angular/common";
import * as i12 from "../core/i18n.service";
import * as i13 from "ngx-cookie-service";
import * as i14 from "../core/rest.service";
import * as i15 from "../services/communication.service";
import * as i16 from "../services/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message", [], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(1, 245760, null, 0, i3.MessageComponent, [i4.ToastrService, i5.MessageService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i6.View_LoaderComponent_0, i6.RenderType_LoaderComponent)), i1.ɵdid(3, 245760, null, 0, i7.LoaderComponent, [i8.LoaderService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i10.AppComponent, [i11.Location, i12.I18nService, i13.CookieService, i9.Router, i8.LoaderService, i14.RestService, i15.CommunicationService, i16.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
