import { RestService } from './../../core/rest.service';
import { Component, OnInit, AfterViewInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { messagingService } from '../notification/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap';
import { AppConfig } from '../../../config/app.config';
import { MessageService } from 'src/core/message/message.service';
import { CommunicationService } from 'src/services/communication.service';
import { AbstractService } from 'src/services/abstract.service';
declare const gapi: any;
@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService]
})

export class LoginComponent implements OnInit  , OnDestroy {
  public localState = { value: '' };
  formGroup: FormGroup;

      // TO MAKE RESPONSIVE
    isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  sendOtpFormGroup: FormGroup;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  @ViewChild('registerModal',  {static: false}) registerModal: ModalDirective;
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;
  constructor(private authService: AuthService, private route: ActivatedRoute , private router: Router, public modalService: BsModalService,
              public ngZone: NgZone, public cookieService: CookieService, public messagingService: MessageService,
              public translate: TranslateService, public communicationService:CommunicationService, public abstractService:AbstractService,
              private CookieService: CookieService) {
                // if (this.cookieService.get('userId')) {
                //   this.router.navigate(['/home']);
                // } @TODO
                this.lastUrl =  this.cookieService.get('lastUrl');
                // this.deleteAllCookies();
  this.conferenceShortName = this.route.snapshot.queryParamMap.get('conference') ? this.route.snapshot.queryParamMap.get('conference') : 'IEEE-9';
  this.eventShortName = this.route.snapshot.queryParamMap.get('event') ? this.route.snapshot.queryParamMap.get('event') : 'event3' ;
              }
  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
     }
  public ngOnInit() {
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ])
    });
    this.sendOtpFormGroup =  new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)      ])
      });
    this.oneTimePasswordFormGroup =  new FormGroup({
      otpContent: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50)
        ])
        });
    }


onSubmit(values) {
  if (this.formGroup.valid) {
  this.authService.login(values.Email, values.Password).subscribe((res) => {
    this.setCookieAndNavigate(res.response.userId ,res.response.adminAuthority, res.response.userName);
    // this.paneAdminlList(res.response.userId ,res.response.adminAuthority, res.response.userName);
}, (err) => {
});
  } else {
    this.validateAllFormFields(this.formGroup);
  }
}
// public paneAdminlList(userId,adminAuthority,userName) {
//   this.abstractService.getAdminPanelList().subscribe((res) => {
//     if(res.response.entries) {
//       const reviewerList = res.response.entries;
//         if (reviewerList.find((email) => email.shortName === userId)) {
//           this.setCookieAndNavigate(userId , adminAuthority , userName);
//           this.cookieService.set('adminPanel', 'true');
//         } else {
//           this.setCookieAndNavigate(userId , adminAuthority , userName);
//         }
//     } else {
//       this.setCookieAndNavigate(userId , adminAuthority , userName);
//     }
//   });
//  }
  onReset() {
    this.formGroup.reset();
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 


  goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  setCookieAndNavigate(userId , adminAuthority , userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
    if (this.lastUrl) {
    this.router.navigateByUrl(this.lastUrl);
    this.cookieService.delete('lastUrl');
    } else {
      // derma need to navigate admin/submissions
      this.router.navigate(['/admin/submissions']);
    }
  }




  ngOnDestroy() {
  if (this.modalRef !== undefined) {
    this.modalRef.hide();
  }
}

goToRegister() {
  this.router.navigate(['/register']);
}
// public deleteAllCookies() {
//   const cookies = document.cookie.split(';');
//   // tslint:disable-next-line:prefer-for-of
//   for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i];
//       const eqPos = cookie.indexOf('=');
//       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//       if (name !== 'lastUrl') {
//         document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
//       }
//   }
// }
}
